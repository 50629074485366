<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-list two-line nav>
    <v-list-item-group color="primary">
      <conversations-list-item v-for="item in items" :key="item" :uid="item" />
    </v-list-item-group>
  </v-list>
</template>

<script>
  import ConversationsListItem from '@/modules/messenger/components/conversations/ConversationsListItem'

  export default {
    name: 'ConversationsList',
    components: {ConversationsListItem},
    props: {
      items: {
        type: Array,
        required: true
      }
    }
  }
</script>
