<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div v-if="!loading" style="width:100%;background-color: white;height: calc(100vh - 64px);position: relative;">
    <conversations />
    <div v-if="conversation" style="margin-left: 360px;">
      <messages-header :participants="conversation.participants" />
      <messages-list :conversation="conversation" />
      <messages-input :conversation="conversation" />
    </div>
  </div>
  <page-loader v-else />
</template>

<script>
  import MessagesHeader from '@/modules/messenger/components/messages/MessagesHeader'
  import MessagesList from '@/modules/messenger/components/messages/MessagesList'
  import MessagesInput from '@/modules/messenger/components/messages/MessagesInput'
  import Conversations from '@/modules/messenger/components/conversations/Conversations'
  import PageLoader from '@/modules/core/layout/PageLoader'

  export default {
    name: 'Messenger',
    components: {PageLoader, Conversations, MessagesInput, MessagesList, MessagesHeader},
    data(){
      return {
        loading: true
      }
    },
    computed: {
      conversation(){
        return this.$store.getters['messenger/conversations/getActive']
      }
    },
    async mounted() {
      if (this.conversations) {
        return
      }
      await this.$store.dispatch('messenger/conversations/fetch')
      this.loading = false
    }
  }
</script>
