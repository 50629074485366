<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-app-bar flat style="background-color:rgba(245,245,245,0.8)" class="elevation-x">
    <v-list-item>
      <v-list-item-avatar>
        <user-avatar-img
          v-for="(participant,i) in participants"
          :key="i"
          :user="participant.user"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-medium">
          {{ title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          en ligne status
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-app-bar>
</template>

<script>
  import {formatParticipantsName} from '@/modules/messenger/utils'
  import UserAvatarImg from '@/modules/core/layout/UserAvatarImg'

  export default {
    name: 'MessagesHeader',
    components: {UserAvatarImg},
    props: {
      participants: {
        type: Array,
        required: true
      }
    },

    computed: {
      title() {
        return formatParticipantsName(this.participants) || this.$auth.user.full_name
      }
    }
  }
</script>
