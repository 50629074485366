<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-navigation-drawer absolute permanent width="360">
    <v-subheader>Trouver une discussion</v-subheader>
    <conversations-search-input ref="searchInput" class="px-2" @input="contacts = $event" />
    <v-divider class="my-1" />
    <v-subheader>Mes conversations</v-subheader>
    <messenger-contacts-list v-if="contacts" :items="contacts" @select="onContactSelect" />
    <conversations-list v-else :items="conversations || []" />
  </v-navigation-drawer>
</template>

<script>
  import ConversationsSearchInput from '@/modules/messenger/components/conversations/ConversationsSearchInput'
  import MessengerContactsList from '@/modules/messenger/components/contacts/MessengerContactsList'
  import ConversationsList from '@/modules/messenger/components/conversations/ConversationsList'
  import * as Conversations from '@/modules/messenger/api/conversations'
  import {Conversation} from '@/modules/messenger/models'

  export default {
    name: 'Conversations',

    components: {ConversationsList, MessengerContactsList, ConversationsSearchInput},

    data(){
      return {
        loading: false,
        contacts: null
      }
    },
    computed: {
      conversations(){
        return this.$store.state.messenger.conversations.items
      }
    },
    methods: {
      async onContactSelect(uid){
        // TODO A refactor, utilisé dans /messenger qui n'est pas encore accessible
        // TODO: Let's use now active for "focused" chat window maybe ?
        let {data} = await Conversations.getByParticipant(uid)
        Conversation.save(data)

        this.$store.commit('messenger/conversations/setActive', data.uid)
        this.contacts = null
        this.$refs.searchInput.clear()
      }
    }
  }
</script>
